<script lang="ts" setup>
import { pushProductClickCtaEvent } from "~/utils/analytics/clickCta"

const handleClick = (label: string) => {
  pushProductClickCtaEvent(label)
  navigateTo(customPaths.logInSignIn)
}
</script>

<template>
  <div
    class="
      col-span-full
      mt-3
      block
      cursor-pointer
      rounded-lg
      bg-tiffany-10
      px-3
      py-2
      md:hidden
    "
    @click.prevent="
      () => handleClick($t('productPage.detail.firstTimeHereSignIn'))
    "
  >
    <!-- bg-tiffany-10 -->
    <div class="mb-2 flex gap-2">
      <UtilsIcon
        name="Pricetag.svg"
        class="h-6 w-6"
        fetch-priority="high"
        :preload="true"
      />
      <span class="beaver-bold">
        {{ $t("productPage.detail.firstTimeHere") }}
      </span>
    </div>
    <!-- TODO: dynamic promo code -->
    <UtilsMarkdown
      class="markdown__link-underlined beaver-medium"
      :content="
        $t('productPage.detail.firstTimeHereDescriptionMD', {
          signIn: $t('productPage.detail.firstTimeHereSignIn'),
          link: customPaths.logInSignIn
        })
      "
    />
  </div>
</template>
