<script setup lang="ts">
// import type { GA4Events } from "nuxt-ga4"
import { formatMoney } from "~/utils/formatters"
import { BundleProps } from "./Bundle.props"
import { BundleProductCardProps } from "~/components/ProductPage/Utils/BundleProductCard.props"
import { addToCartPosition } from "~/utils/constants"
import { analyticsItemListTypes } from "~/utils/analytics/categories"

const props = withDefaults(defineProps<BundleProps>(), {
  type: "default"
})

const emit =
  defineEmits<{
    (e: "onAdd", products: BundleProductCardProps[]): void
  }>()

const selectedProducts = ref<BundleProductCardProps[]>(props.products)

const totalPrice = computed(() =>
  formatMoney(
    selectedProducts.value.reduce(
      (acc, { currentPrice }) => acc + currentPrice,
      0
    )
  )
)

const totalOldPrice = computed(() =>
  formatMoney(
    selectedProducts.value.reduce((acc, { oldPrice }) => {
      if (!oldPrice) return acc
      return acc + oldPrice
    }, 0)
  )
)

const isSelected = (sku: string) =>
  !!selectedProducts.value.find(({ productCode }) => productCode === sku)

const toggleProduct = (productID: string, isSelected: boolean) => {
  const selectedProduct: BundleProductCardProps | undefined =
    props.products.find(({ productCode }) => productCode === productID)

  if (isSelected && selectedProduct) {
    selectedProducts.value.push(selectedProduct)
  } else if (!isSelected) {
    selectedProducts.value = selectedProducts.value.filter(
      ({ productCode }) => productCode !== productID
    )
  }
}

const { addToCart } = useCart()

const handleAddToCart = () => {
  selectedProducts.value.forEach(async (product, index) => {
    const gaItem: typeof GA4Entities["gaItem"] | undefined = useGAEntity<
      typeof GA4Entities["gaItem"]
    >(`ga_item_${product.productCode}`)

    const { sendEventAddToCart, mapProduct } = useGA4SiteEvents()
    gaItem.value = mapProduct(product, index, analyticsItemListTypes.crossSell)

    addToCart(
      {
        sku_code: product.productCode,
        metadata: {
          product_image: product.productImage,
          slug: `${product.path}`,
          price: product.currentPrice,
          oldPrice: product?.oldPrice ?? 0, // TODO: oldPrice should be optional on metadata!!!
          inStock: product?.inStock ?? false,
          unitType: product?.unitType ?? "",
          expirationDate: product.expirationDate ?? "",
          isDeductable: product.isDeductable ?? false,
          ga4Item: gaItem?.value ?? {}
        }
      },
      1
    )

    if (Object.keys(gaItem?.value ?? {}).length) {
      sendEventAddToCart(gaItem?.value ?? {}, {
        currentPrice: product?.currentPrice?.toString() ?? "",
        position: addToCartPosition.bundle
      })
    }
  })
}

onMounted(() => {
  selectedProducts.value = props.products
})
</script>

<template>
  <div
    class="
      bundle
      pig
      flex flex-col
      gap-y-4
      bg-tiffany-10
      p-4
      md:h-full
      md:rounded-lg
    "
  >
    <h5 class="hyppo-bold">{{ $t(`productPage.bundle.${type}.title`) }}</h5>
    <p>{{ $t(`productPage.bundle.${type}.description`) }}</p>
    <GAItem
      :id="`bundle-${type}`"
      :item="products"
      :list-name="analyticsItemListTypes.crossSell"
    >
      <GAViewItemList>
        <div
          v-if="products?.length"
          class="
            bundle__products
            flex flex-col
            gap-4
            rounded
            bg-white
            p-4
            md:flex
            md:h-full
            md:flex-row
            md:justify-between
            md:gap-x-6
            md:px-4
            md:py-6
          "
        >
          <GAListBoundleProducts :name="analyticsItemListTypes.crossSell">
            <template v-for="product in products" :key="product.productCode">
              <ProductPageUtilsBundleProductCard
                class="md:flex-1"
                v-bind="product"
                :type="type"
                :selectable="type === 'default'"
                :selected="isSelected(product.productCode)"
                @onSelect="toggleProduct"
              />
            </template>
          </GAListBoundleProducts>
        </div>
      </GAViewItemList>
    </GAItem>
    <div
      class="
        bundle__controls
        flex flex-col
        space-y-4
        md:flex-row
        md:items-center
        md:justify-between
        md:space-y-0
      "
    >
      <div class="flex items-center gap-x-4">
        <p>
          {{ $t(`productPage.bundle.${type}.totalPrice`) }}
          <strong class="hyppo-bold text-green-main">
            {{ totalPrice }}
          </strong>
        </p>
        <small
          v-if="type === 'discount'"
          class="beaver text-black-80 line-through"
        >
          {{ totalOldPrice }}
        </small>
      </div>
      <UtilsButton
        theme="outlined-green"
        :isDisabled="!selectedProducts?.length"
        :text="$t(`productPage.bundle.${type}.addProducts`)"
        @click="handleAddToCart"
      />
    </div>
  </div>
</template>
