<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { ProductAlgolia } from "~/utils/mapProducts"
import { NotAvailableProps } from "./NotAvailable.props"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { FormKitNode } from "@formkit/core"
import { getSkuItemMetadata } from "~/utils/savedForLater/savedForLater"
import { SaveForLaterProductForAction } from "~/composables/useSavedForLater"
import { pushProductClickCtaEvent } from "~/utils/analytics/clickCta"
import { cleanObject } from "~/utils/analytics/analytics"

const { sendEventErrorForm } = useGA4SiteEvents()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const isLogged = useIsLogged()

const props = defineProps<NotAvailableProps>()

const { data: result, pending } = useLazyFetch(
  `/api/algolia/getRecommendations/recommend-7/${props.product.details.productId}`,
  {
    server: false
  }
)

const { openModal } = useModal()
const notifyMe = ref<boolean>(false)

const emailValue = ref(user.value?.email ?? "")
const escapedEmailValue = ref(user.value?.email ?? "&nbsp;")

const isDisabled = computed(() => emailValue.value === user.value?.email)

const similarProductCode = useState("similar-product-code", () => "")
const emailState = useState("notification-subscription-mail", () => "")

const emit =
  defineEmits<{
    (e: "handleNotifyMe"): void
  }>()

const handleSubmit = async () => {
  notifyMe.value = true
  emailState.value = emailValue.value

  emit("handleNotifyMe")
}

const handleSimilarProducts = (label: string) => {
  similarProductCode.value = props.product.details.productId
  openModal("similar-products")
  pushProductClickCtaEvent(label)
}

const similarProducts = computed(() => {
  const products: ProductAlgolia[] =
    _flattenDeep(result.value?.results?.map((item) => item.hits)) ?? []
  return mapProductsAlgolia(products)
})

onMounted(async () => {
  if (!!isLogged.value && !!user.value?.email) {
    emailValue.value = user.value?.email
    escapedEmailValue.value = user.value?.email ?? "&nbsp;"
  }
})

watch(user, () => {
  if (!user.value?.email) return
  emailValue.value = user.value?.email
  escapedEmailValue.value = user.value?.email ?? "&nbsp;"
})

const { emailValidation } = useLoqate()
async function emailCheck(node: FormKitNode) {
  const isEmailValid = await emailValidation(node.value as string)
  return isEmailValid
}
const product = computed((): SaveForLaterProductForAction => {
  return {
    productId: props.product.details.productId,
    skuId: props.product.details.skuId,
    metadata: getSkuItemMetadata(
      props.product.details?.gallery[0],
      props.product.details.slug,
      props.product.details.price,
      props.product.details.oldPrice,
      props.product.details.stockQuantity,
      props.product.description?.infos?.unitOfMeasure,
      props.product.details.expirationDate,
      props.product.details.isDeductable,
      cleanObject(props.product.gaItem)
    )
  }
})
</script>

<template>
  <div class="flex flex-wrap bg-grey-main px-4 py-4">
    
<FormKitLazyProvider config-file="true">
<div class="mb-4 flex items-center justify-between gap-2">
      <h3 class="dolphin-bold mb-0 flex w-full items-center gap-3">
        <UtilsIcon
          name="CloseCircleFull.svg"
          color="negative-main"
          class="inline-block h-6 w-6"
          fetch-priority="high"
          :preload="true"
        />
        {{ $t("productPage.purchaseType.notAvailable.title") }}
      </h3>
    </div>
    <UtilsMarkdown
      class="beaver markdown__strong-beaver-bold w-full"
      :key="'notifyText-' + notifyMe + emailValue"
      :class="notifyMe ? 'mb-4' : 'mb-5'"
      :content="
        notifyMe
          ? $t(
              'productPage.purchaseType.notAvailable.descriptionAfterSubmitting',
              { email: escapedEmailValue }
            )
          : isLogged
          ? $t(
              'productPage.purchaseType.notAvailable.descriptionBeforeSubmittingLogged',
              { email: escapedEmailValue }
            )
          : $t(
              'productPage.purchaseType.notAvailable.descriptionBeforeSubmitting'
            )
      "
    />

    <FormKit
      v-if="!notifyMe"
      id="productNotAvailableForm"
      type="form"
      @submit-invalid="sendEventErrorForm('findOrderForm')"
      form-class="filters-modal__form w-full"
      :actions="false"
      @submit="handleSubmit"
      #default="{ state: { valid } }"
    >
      <TextfieldsEmail
        v-show="!isLogged"
        v-model="emailValue"
        :loqate-check="!isLogged"
        class="mb-5 w-full"
        :label="$t('dialogsAndModals.productNotAvailable.yourEmail')"
        :disabled="isDisabled"
      />
      <div class="mb-4 flex items-stretch gap-2">
        <UtilsButton
          type="submit"
          class="w-full"
          theme="outlined-green-shadowed"
          :text="$t('dialogsAndModals.productNotAvailable.notifyMe')"
          :isInnerFullHeight="true"
        />
        <ProductSavedForLaterAction
          :product="product"
        ></ProductSavedForLaterAction>
      </div>
    </FormKit>

    <UtilsButton
      :text="$t('dialogsAndModals.productNotAvailable.privacy')"
      target="_blank"
      :path="`/privacy`"
      class="custom-btn-no-padding custom-btn-mouse-medium mb-5"
      theme="link-green"
    />

    <SkeletonAnimatedPlaceholder v-if="pending" height="34px" width="100%" />

    <p v-if="similarProducts.length" class="beaver-medium w-full">
      {{ $t("dialogsAndModals.productNotAvailable.otherProducts") }}
      <AppLink
        @click="
          () =>
            handleSimilarProducts(
              $t('dialogsAndModals.productNotAvailable.similarProducts')
            )
        "
        class="
          beaver-medium
          cursor-pointer
          text-green-main
          underline
          hover:text-green-300
        "
      >
        {{ $t("dialogsAndModals.productNotAvailable.similarProducts") }}
      </AppLink>
    </p>
</FormKitLazyProvider>

  </div>
</template>
