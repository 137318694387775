<script setup lang="ts">
import { formatMoney, formattedPricePerUnit } from "~/utils/formatters"
import { SingleProps } from "./Single.props"
import { productUtils } from "~/utils/product"

import { useStorage } from "@vueuse/core"
import {
  algoliaEventAddToCartAfterSearch,
  algoliaEventAddToCart
} from "~/utils/algolia"
import { PRODUCT_ADDED_TO_CART } from "~/config/algolia-insights"
import { algoliaSearch } from "~/config/algolia"
import { SaveForLaterProductForAction } from "~/composables/useSavedForLater"
import { getSkuItemMetadata } from "~/utils/savedForLater/savedForLater"
import { cleanObject } from "~/utils/analytics/analytics"

const props = withDefaults(defineProps<SingleProps>(), {
  pricePerUnit: 0,
  unitType: ""
})

const quantity = ref(1)
const isLoading = ref(false)

const packageNumber = computed(
  () =>
    `(${quantity.value} confezion${
      quantity.value === 1 ? "e" : "i"
    } ${formatMoney(props.price * quantity.value)})`
)

const emit =
  defineEmits<{
    (e: "onPurchase", value: number): void
    (e: "onSaveForLater"): void
  }>()

const algoliaLastClickedProduct = useStorage("algolia-last-clicked-product", {
  queryId: "",
  path: ""
})

const handlePurchase = (value: number) => {
  const route = useRoute()
  const algolia = algoliaSearch()
  if (
    route.path == algoliaLastClickedProduct.value.path &&
    algoliaLastClickedProduct.value?.queryID
  ) {
    algoliaEventAddToCartAfterSearch(
      algolia.indices.products,
      `${PRODUCT_ADDED_TO_CART}`,
      algoliaLastClickedProduct.value?.queryID,
      [`product_${props.product?.details?.productId}`]
    )
  } else {
    algoliaEventAddToCart(
      algolia.indices.products,
      `${PRODUCT_ADDED_TO_CART}`,
      [`product_${props.product?.details?.productId}`]
    )
  }
  isLoading.value = true
  emit("onPurchase", value)
  setTimeout(() => {
    // TODO: IT'S POSSIBILE TO MANAGE THIS ISLOADING VARIABLE LIKE A PROP FROM THE PARENT PAGE
    isLoading.value = false
  }, 1000)
}

const handlePurchaseThrottled = useThrottleFn(handlePurchase, 1000)

const product = computed((): SaveForLaterProductForAction => {
  return {
    productId: props.product.details.productId,
    skuId: props.product.details.skuId,
    metadata: getSkuItemMetadata(
      props.product.details?.gallery[0],
      props.product.details.slug,
      props.product.details.price,
      props.product.details.oldPrice,
      props.product.details.stockQuantity,
      props.product.description?.infos?.unitOfMeasure,
      props.product.details.expirationDate,
      props.product.details.isDeductable,
      cleanObject(props.product.gaItem)
    )
  }
})
</script>

<template>
  <div
    class="
      single-purchase-type-wrapper
      flex flex-col
      gap-4
      rounded-lg
      p-4
      shadow-01
    "
  >
    <UtilsQuantityPicker
      :quantity="quantity"
      :min="1"
      :max="stockQuantity ?? 1"
      @update:modelValue="quantity = $event"
    />

    <p
      class="mouse md:beaver text-negative-main"
      v-if="quantity === stockQuantity"
    >
      {{
        $t(
          "productPage.purchaseType.organism.errorMessages.maxQuantity.inStock"
        )
      }}
    </p>

    <p class="beaver flex flex-wrap items-center gap-1 text-black-80">
      {{ $t("productPage.purchaseType.organism.costPerPackage") }}
      <span class="beaver-bold">{{ formatMoney(price) }}</span>
      <template
        v-if="productUtils.isDisplayablePricePerUnit(pricePerUnit, unitType)"
      >
        <span class="mouse">
          {{ formattedPricePerUnit(formatMoney(pricePerUnit), unitType) }}
        </span>
      </template>
    </p>

    <div class="flex items-stretch gap-2">
      <UtilsButton
        @click="handlePurchaseThrottled(quantity)"
        :fluid="true"
        :isInnerFullHeight="true"
      >
        <template #icon>
          <UtilsLoader v-if="isLoading" />
          <p v-else class="flex flex-wrap justify-center gap-1">
            <span>{{ $t("productPage.purchaseType.organism.addToCart") }}</span>
            <span>{{ packageNumber }}</span>
          </p>
        </template>
      </UtilsButton>

      <ProductSavedForLaterAction
        :product="product"
        @onSaveForLater="emit('onSaveForLater')"
      ></ProductSavedForLaterAction>
    </div>
  </div>
</template>
